<template>
  <form>
    <input type="email" v-model="email" class="form-control form-control-lg mt-3" placeholder="E-mail"/> 
    <div class="text-danger mt-3">
      {{error}}
    </div>
    <div class="d-grid gap-2 mt-4">
    <button class="btn btn-primary" :disabled="loading" type="button" @click="enviar">
        <span v-if="!loading">Enviar</span>
        <font-awesome-icon :icon="['fas', 'sync-alt']" spin 
        v-if="loading"/>
    </button>

  </div>
  </form>
</template>

<style scoped>
  .options{
    display: flex;
    justify-content:space-between;
    font-size: 0.85rem;
  }
  .text-danger{
    font-weight: 500
  }
  .form-control-lg {
    font-size: 1rem;
  }
</style>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'
import {emailRecovery} from '@/services/auth';

library.add(faSyncAlt);

export default {
  name: 'Forgot',
  props: {
  },
  data(){
    return {
      email: '',
      loading: false,
      error: '',
    }
  },
  methods: {
    enviar() {
      this.error = '';

      if(!this.email) {
        this.error = 'E-mail inválido';
        return;
      }


      this.loading = true;
      let msg = 'Não foi solicitar a troca de senha. Tente novamente mais tarde.';

      emailRecovery(this.email)
          .then(data => {
              if (!data || data.isAxiosError || data.tokenAcesso) {
                  this.error = 'Erro ao enviar e-mail.';
              }
              else {
                  alert('E-mail de recuperação de senha enviado com sucesso!');
                  this.back();
              }
              
          })
          .catch(err => {
            this.error = msg;
            console.log(err);
          })
          .finally(() => {
            this.loading = false;
          });     
    },
    back() {
      this.$emit("back")
    }
  },
  beforeMount() {
    
  },
  mounted(){
     
  }
}
</script>
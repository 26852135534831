<template>
  <form>
    <router-link to="/" class="d-block text-left mt-3">
        <font-awesome-icon :icon="['fas', 'arrow-left']" size="lg" />

     </router-link>
    <div class="row">
      <div class="col-lg-12 mb-3">
        <label  class="form-label"
          >Nova senha</label
        >
        <input
          type="password"
          class="form-control"
          v-model="obj.novaSenha"
          placeholder="******"
          autocomplete="current-password" 
        />
      </div>
      <div class="col-lg-12 mb-3">
        <label  class="form-label"
          >Confirmacão da nova senha</label
        >
        <input
          type="password"
          class="form-control"
          v-model="obj.confirmacaoNovaSenha"
          placeholder="******"
          autocomplete="new-password" 

        />
      </div>
    </div>
    <div class="text-danger mt-3">
      {{error}}
    </div>
    <div class="d-grid gap-2 mt-4">
    <button class="btn btn-primary" :disabled="loading" type="button" @click="enviar">
        <span v-if="!loading">Enviar</span>
        <font-awesome-icon :icon="['fas', 'sync-alt']" spin 
        v-if="loading"/>
    </button>

  </div>
  </form>
</template>

<style scoped>
  .options{
    display: flex;
    justify-content:space-between;
    font-size: 0.85rem;
  }
  .text-danger{
    font-weight: 500
  }
  .form-control-lg {
    font-size: 1rem;
  }
  
</style>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'
import {trocar} from '@/services/auth';

library.add(faSyncAlt);

export default {
  name: 'Forgot',
  props: {
  },
  data(){
    return {
      obj: {
        novaSenha: '',
        confirmacaoNovaSenha: '',
        codigo: ''
      },
      error: '',
      loading: false,
    }
  },
  methods: {
    back() {
      this.$emit("back");
      this.$router.push('/');
    },
    enviar() {
      this.error = '';

      if(this.obj.novaSenha.length < 6) {
        this.error = 'A senha deve ter no mínimo 6 dígitos';
        return;
      }

      if(this.obj.novaSenha !== this.obj.confirmacaoNovaSenha) {
        this.error = 'O campo confirmação deve ser idêntico ao campo senha';
        return;
      }
      
      let msg = 'Não foi possível trocar a senha. Tente novamente mais tarde.';
      
      this.loading = true;
      trocar(this.obj)
          .then(response => {
            this.loading = false;

            if(response.status != 204) {
              this.error = msg;
            }
            else {
              alert('Senha alterada com sucesso!');
              this.back();
            }
          })
          .catch(err => {
            this.error = msg;
            console.log(err);
          })
          .finally(() => {
            this.loading = false;
          });    
    },
  },
  beforeMount() {
    
  },
  mounted(){
    this.obj.codigo = this.$route.params['id'];
    if(!this.obj.codigo)
      this.back();
  },
  
}
</script>
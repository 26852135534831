<template>
  <div id="person">
    <div class="row">
      <div class="col-lg-12 mb-3">
        <label  class="form-label">E-mail de acesso</label>
        <input
          type="text"
          class="form-control"
          v-model="obj.email"
          readonly="readonly"
        />
      </div>
      <div class="col-lg-12 mb-3">
        <label  class="form-label"
          >Senha</label
        >
        <input
          type="password"
          class="form-control"
          v-model="obj.senha"
          
        />
      </div>
      <div class="col-lg-12 mb-3">
        <label  class="form-label"
          >Confirmação de senha</label
        >
        <input
          type="password"
          class="form-control"
          v-model="obj.confirmacaoSenha"
          
        />
      </div>
      
      <div class="col-lg-12 btn-flex">
        <div class="d-grid gap-2">
          <button type="button" :disabled="sending" @click="submit" class="btn btn-primary send">
            <span v-if="!sending">{{buttonText || 'salvar' }}</span>
            <font-awesome-icon :icon="['fas', 'sync-alt']" spin 
            v-if="sending"/>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.btn-flex {
  display: flex;
  align-items: center;
}
.btn-flex > div {
  flex: 1;
}
#person {
  text-align: left
}
</style>

<script>
import {getItemOrFetch} from '@/services/localdb';
import {personKey} from '@/services/util';

export default {
  data() {
    return {
      obj: {},
      states: [],
      cities: [],
    }
  },
  props: {
    buttonText: String,
    emiter: String,
    sending: Boolean,
    edit: Boolean,
    newUser: Object
  },
  methods: {
    validate() {
      this.errors = [];

      if(!this.obj.senha)
        this.errors.push('Campo "Senha" inválido');

      if(String(this.obj.senha).length < 6)
        this.errors.push('Campo "Senha" deve ter no mínimo 6 caracteres');
    
      if(this.obj.senha !== this.obj.confirmacaoSenha)
        this.errors.push('Campo "Senha" deve ser idêntico ao campo "Confirmação senha"');

      return this.errors.length === 0;
    },
    submit(){
      if(this.emiter) {
        if(this.validate()) {
          this.$emit(this.emiter,this.obj);
        }
        else{
          alert('Erro na validação dos campos!')
        }
      }
    },
    async setCities(clearCity){
      const cityKey = 'city-'+this.obj.codigoEstado;
      
      if(clearCity) {
        this.obj.codigoCidade = '';
      }
      let savedData = await getItemOrFetch(cityKey,`/v1/cadastros/estados/${this.obj.codigoEstado}/cidades`);
      
      this.cities = savedData;
    }
  },
  async mounted(){
  const stateKey = 'state';
  this.obj = this.newUser || this.obj;

  if(this.edit){
      
      this.obj = await getItemOrFetch(personKey,'/v1/lojistas');
      
      this.states = await getItemOrFetch(stateKey,'/v1/cadastros/estados');

      await this.setCities(false);
    }
  }
};
</script>